import { createJobOpportunityApi, deleteJobOpportunityApi, getJobOpportunitiesApi, updateJobOpportunityApi } from "../../api/jobOpportunityApi"
import { displayErrorToast, displaySuccessToast } from "../../helper/toast_notification_function"
import { deleteJobOpportunityType, saveJobOpportunities, startGettingJobOpportunitiesData } from "../constants/job_opportunities_constants"


 export const  getAllJobOpportunities = (dispatch,token,navigate) =>{
     dispatch({
        type:startGettingJobOpportunitiesData,        
    })
    getJobOpportunitiesApi(token,dispatch,navigate).then((e)=>{
      return dispatch({
          type:saveJobOpportunities,
          payload:e.data.JobOpportunities ? e.data.JobOpportunities : []
          
      })
  })
}


export const createJobOpportunity = (data, token, dispatch ,navigate) => {
    createJobOpportunityApi(data, token, dispatch ,navigate)
    .then((res) => {
        displaySuccessToast(`Job Opportunity Created successfully!`)
        getAllJobOpportunities(dispatch,token,navigate)
      })
      .catch(error => {
        displayErrorToast(error)
      })
}


export const updateJobOpportunity = (data, token, dispatch ,navigate) => {
    updateJobOpportunityApi(data, token, dispatch ,navigate)
    .then((res) => {
        displaySuccessToast(`Job Opportunity Updated successfully!`)
        getAllJobOpportunities(dispatch,token,navigate)
      })
      .catch(error => {
        displayErrorToast(error)
      })
}



export const deleteSingleJobOpportunity = (selectedDataRow,token,dispatch, navigate) => {
    const selectedJobOpportunityId = selectedDataRow.PKJobOpportunityId
    deleteJobOpportunityApi(selectedJobOpportunityId,token,dispatch, navigate)
      .then(() => {
        displaySuccessToast(`${selectedDataRow.Title} deleted successfully`)
        return dispatch({
          type: deleteJobOpportunityType,
          payload: selectedJobOpportunityId
        })
      })
      .catch(error => {
        displayErrorToast(error)
      })
  }



