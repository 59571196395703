import { combineReducers } from 'redux'
import userStateReducer from './userDataReducers/userState'
import userTokensReducer from './userDataReducers/userTokens'
import productsReducer from './dataReducers/productsData'
import categoriesReducer from './dataReducers/categoriesData'
import tagsReducer from './dataReducers/tagsData'
import contactFormDataReducer from './dataReducers/contactformData'
import jobOpportunitiesReducer from './dataReducers/jobOpportunitiesData'
import publicationsReducer from './dataReducers/publicationsData'
import pressReleasesReducer from './dataReducers/pressReleasesData'
import eventsReducer from './dataReducers/eventsData'
import citiesReducer from './dataReducers/getCitiesReducer'
import matabsReducer from './dataReducers/getMatabsReducer'


const reducers = combineReducers({
  userStateReducer,
  userTokensReducer,
  eventsReducer,
  productsReducer,
  pressReleasesReducer,
  publicationsReducer,
  jobOpportunitiesReducer,
  categoriesReducer,
  tagsReducer,
  contactFormDataReducer,
  citiesReducer,
  matabsReducer
})

export default reducers
