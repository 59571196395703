import { logoutUserType } from "../../constants/common_constants"
import { saveContactFormSubmissionsData, startGettingContactFormSubmissionsData } from "../../constants/contact_form_submissions_constants"

const INITIAL_STATE = {
    isLoading:true,
    contactFormData:[],
}


const contactFormDataReducer = (state = INITIAL_STATE,action) => {

    const {type, payload} = action

    switch (type) {
        case saveContactFormSubmissionsData:
            return {
                ...state,
                isLoading:false,
                contactFormData:payload
            }
        case startGettingContactFormSubmissionsData:
            return {
                ...state,
                isLoading:true,
            }       
    case logoutUserType:
        return {
          ...state,
          contactFormData: [],
          isLoading: true
        }
        default:
            return state
    }
}


export default contactFormDataReducer