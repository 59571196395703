import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

export const TagsCheckBoxes = ({ allTags, onChange,tags}) => {
  const [checked, setChecked] = React.useState([])
  const [tagsArray, setTagsArray] = React.useState([])
  const [tagsForDatabase, setTagsForDatabase] = React.useState({})


  const [checkedSingleBox, setCheckedSingleBox] = React.useState(false)
  const [checkedAllBoxes, setCheckedAllBoxes] = React.useState(false)

  let indeterminateCheckBoxesStatus = () => {
    let status =
      tagsArray.filter(e => e?.isChecked === true).length !== 0 &&
      tagsArray.filter(e => e?.isChecked === true).length < tagsArray.length
    setCheckedSingleBox(status)
  }

  let allBoxesStatus = () => {
    let status =
      tagsArray.filter(e => e.isChecked === true).length === tagsArray.length
    setCheckedAllBoxes(status)
  }

  let getTrueCheckBoxes = () => {
    let activeTagsArray = []

    tagsArray.filter(e => {
      if(e.isChecked === true){
       return activeTagsArray = [...activeTagsArray,e.PKTagId]
      }
      })

    let tagsObj = {
      target: {
        name: 'tags',
        value: JSON.stringify(activeTagsArray)
      }
    }
    // setTagsForDatabase(tagsObj)
    if(activeTagsArray.length !== 0 ){
      onChange(tagsObj)
    }
    
  }

  useEffect(() => {
    getTrueCheckBoxes()
 let count = 0    
 
    allTags &&
      allTags.forEach(element => {
        let obj = {
          PKTagId: element.PKTagId,
          NameOfTag: element.NameOfTag,
          isChecked: false
        }
        tagsArray.push(obj)
        setTagsArray([...tagsArray])


        if(tags && tags.length !== 0){
          tags.forEach(e => {
            if(e.FKTagId === element.PKTagId ){
              let obj = {
                PKTagId: element.PKTagId,
                NameOfTag: element.NameOfTag,
                isChecked: true
              }
              tagsArray[count] = obj
              // tagsArray.push(obj)
              setTagsArray([...tagsArray])

            }
          })
          count++ 
        }
        indeterminateCheckBoxesStatus()
        allBoxesStatus()
       })
  }, [])

  let handleChange2 = (element, ind) => {
    tagsArray[ind].isChecked = element.target.checked
    setTagsArray([...tagsArray])
    indeterminateCheckBoxesStatus()
    allBoxesStatus()
    getTrueCheckBoxes()
  }

  let onChangeHandle = e => {
    const { name } = e.target
    if (name === 'selectAll') {
      if (checkedAllBoxes) {
        tagsArray.forEach(element => {
          element.isChecked = false
        })
        indeterminateCheckBoxesStatus()
        allBoxesStatus()
      } else {
        tagsArray.forEach(element => {
          element.isChecked = true
        })
        indeterminateCheckBoxesStatus()
        allBoxesStatus()
      }
      setTagsArray([...tagsArray])
    }
    getTrueCheckBoxes()
    
  }

  const children = (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
      {tagsArray.map((e, i) => (
        <FormControlLabel key={i}
          label={e.NameOfTag}
          control={
            <Checkbox
              value={e.PKTagId}
              checked={e.isChecked ? e.isChecked : false}
              onChange={e => handleChange2(e, i)}
            />
          }
        />
      ))}
    </Box>
  )

  return (
    <div>
      <FormControlLabel
        sx={{ mt: 2 }}
        label='Select All'
        name='selectAll'
        control={
          <Checkbox
            checked={checkedAllBoxes}
            indeterminate={checkedSingleBox}
            // indeterminate={tagsArray.filter((e) => e?.isChecked !== true).length === tagsArray.length}
            onChange={e => onChangeHandle(e)}
          />
        }
      />
      {children}
    </div>
  )
}
