import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import { MdOutlineClose } from "react-icons/md";
import { ToastContainer } from "react-toastify";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { allStyles } from "../../allStyles";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CKEditorComponent } from "../../components/CKEditorComponent";
import { SelectDropdownComponent } from "../../components/SelectDropdownComponent";
import { createMatab, updateMatab } from "../../redux/actions/MatabAction";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <MdOutlineClose />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export const AddUpdateMatab = ({ open, closeClickHandle, editState }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formData, setformData] = React.useState({});

  const [submitButtonDisabled, setSubmitButtonDisabled] = React.useState(true);

  const [cities, setCities] = React.useState([]);
  const [citiesLoading, setCitiesLoading] = React.useState(true);

  const [tags, setTags] = React.useState([]);
  const [tagsLoading, setTagsLoading] = React.useState(true);
  const state = useSelector((state) => state);

  React.useEffect(() => {
    if (state.citiesReducer.cities.length) {
      const Newcities = state.citiesReducer.cities.map((e) => {
        return {
          value: e.id,
          caption: e.city,
        };
      });
      setCities(Newcities);
    }
    setCitiesLoading(state.citiesReducer.isLoading);

    // setTags(state.tagsReducer.tags)
    // setTagsLoading(state.tagsReducer.isLoading)
    setSubmitButtonDisabled(true);
  }, []);

  React.useEffect(() => {
    if (state.citiesReducer.cities.length) {
      const Newcities = state.citiesReducer.cities.map((e) => {
        return {
          value: e.id,
          caption: e.city,
        };
      });
      setCities(Newcities);
    }
    setCitiesLoading(state.citiesReducer.isLoading);

    // setTags(state.tagsReducer.tags)
    // setTagsLoading(state.tagsReducer.isLoading)
  }, [state]);

  const accessToken = useSelector(
    (state) => state.userTokensReducer.tokens.accessToken
  );

  const closeHandle = () => {
    setSubmitButtonDisabled(true);
    closeClickHandle();
  };

  // console.log(categories)

  const submitDataHandle = (e) => {
    e.preventDefault();

    console.log("access token", accessToken);

    if (editState) {
      formData.id = editState.id;
      console.log(editState.id);
      updateMatab(formData, accessToken, dispatch, navigate);
      // updateProduct(formData, accessToken, dispatch, navigate)
      closeClickHandle();
    } else {
      console.log(formData, "=============================>");
      createMatab(formData, accessToken, dispatch, navigate);
      closeClickHandle();
      setformData({});
    }
  };

  const onChangeHandle = (e) => {
    console.log("matab on change", e.target.name, e.target.value);
    let name = e.target.name;
    let val = e.target;

    // console.log(val, name);
    if (name === "Image") {
      setformData({ ...formData, [e.target.name]: val.files[0] });
    } else {
      console.log("final object on change >", {
        ...formData,
        [name]: val.value,
      });
      setformData({ ...formData, [name]: val.value });
    }

    setSubmitButtonDisabled(false);
  };

  console.log(editState, "edit");
  return (
    <div>
      <BootstrapDialog aria-labelledby="customized-dialog-title" open={open}>
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={closeHandle}
        >
          {editState ? "Update Matab" : "Add Matab"}
        </BootstrapDialogTitle>
        <form
          autoComplete="off"
          encType="multipart/form-data"
          onSubmit={(e) => submitDataHandle(e)}
        >
          <DialogContent dividers sx={{ maxHeight: "700px" }}>
            <TextField
              defaultValue={editState ? editState.title : ""}
              name="title"
              sx={allStyles.modalFields}
              label="Title"
              variant="outlined"
              fullWidth
              required
              onChange={(e) => onChangeHandle(e)}
            />

            <TextField
              defaultValue={editState ? editState.contact_number : ""}
              name="contact_number"
              sx={allStyles.modalFields}
              label="Contact Number"
              variant="outlined"
              fullWidth
              onChange={(e) => onChangeHandle(e)}
              required
            />

            <TextField
              defaultValue={editState ? editState.contact_person : ""}
              name="contact_person"
              sx={allStyles.modalFields}
              label="Contact Person"
              variant="outlined"
              fullWidth
              onChange={(e) => onChangeHandle(e)}
              required
            />

            <TextField
              defaultValue={editState ? editState.address : ""}
              name="address"
              sx={allStyles.modalFields}
              label="Address"
              variant="outlined"
              fullWidth
              onChange={(e) => onChangeHandle(e)}
              required
            />

            {cities && (
              <SelectDropdownComponent
                name="city_id"
                caption="Cities"
                sectionTitle="cities"
                onChangeHandle={onChangeHandle}
                menuItems={cities}
                defaultval={editState ? editState.city_id : ""}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button
              disabled={submitButtonDisabled}
              type="submit"
              sx={{ ...allStyles.actionsButtons, mx: 2, my: 1 }}
              variant="outlined"
            >
              {editState ? "Update Matab" : "Create Matab"}
            </Button>
          </DialogActions>
        </form>
      </BootstrapDialog>
      <ToastContainer />
    </div>
  );
};
