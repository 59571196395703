import { getAllContactFormApi } from "../../api/contactFormSubmissionsApi"
import { saveContactFormSubmissionsData, startGettingContactFormSubmissionsData } from "../constants/contact_form_submissions_constants"

export const  getAllContactFormSubmissions = (dispatch,token,navigate) =>{
    dispatch({
       type:startGettingContactFormSubmissionsData,        
   })
   getAllContactFormApi(token,dispatch,navigate).then((e)=>{
     return dispatch({
         type:saveContactFormSubmissionsData,
         payload:e.data.ContactFormSubmissions ? e.data.ContactFormSubmissions : []
         
     })
 })
}
