import { apiHandle } from './apiHandler'

// Get All Job Opportunities Api
export const getJobOpportunitiesApi = async (token, dispatch, navigate) => {
  return await apiHandle(token, dispatch, navigate).get('/jobOpportunities')
}

// Create New Job Opportunity Api
export const createJobOpportunityApi = async (data, token, dispatch, navigate) => {
  return await apiHandle(token, dispatch, navigate).post('/createJobOpportunity',data)
}

// Update Job Opportunity Api
export const updateJobOpportunityApi = async (data, token, dispatch, navigate) => {
  return await apiHandle(token, dispatch, navigate).put(
    `/updateJobOpportunity?id=${data.PKJobOpportunityId}`,data
  )
}

// Delete Job Opportunity Api
export const deleteJobOpportunityApi = async (
PKJobOpportunityId,
  token,
  dispatch,
  navigate
) => {
  return await apiHandle(token, dispatch, navigate).put(`/deleteJobOpportunity?id=${PKJobOpportunityId}`)
}
