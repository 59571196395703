import * as React from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import { MdOutlineClose } from 'react-icons/md'
import { allStyles } from '../allStyles'
import {Stack, Typography } from '@mui/material'




const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}))

const BootstrapDialogTitle = props => {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <MdOutlineClose />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  // onClose: PropTypes.func.isRequired
}

export const DeleteConfirmationModal = ({
  ItemCategory,
  cancelHandle,
  confirmDeteHandle,
  selectedData,
  deleteConfirmationOpen,
  title
}) => {
  return (
    <>
      {deleteConfirmationOpen && selectedData ? (
        <>
          <BootstrapDialog
            aria-labelledby='customized-dialog-title'
            open={deleteConfirmationOpen}
          >
            <BootstrapDialogTitle
              id='customized-dialog-title'
              // onClose={deleteConfirmationOpen}
            >
              {`Do you want to delete this ${ItemCategory}?`}
            </BootstrapDialogTitle>

            <DialogContent>
                <Stack direction='column'>
                  <Typography>{selectedData ? selectedData[title] : ''}</Typography>
                </Stack>
            </DialogContent>
            <DialogActions>
              <Button sx={allStyles.actionsButtons} autoFocus onClick={cancelHandle}>
                Cancel
              </Button>
              <Button sx={allStyles.actionsButtons} onClick={confirmDeteHandle}>Confirm</Button>
            </DialogActions>
          </BootstrapDialog>
        </>
      ) : null}
    </>
  )
}
