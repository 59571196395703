import {logoutUserType } from '../../constants/common_constants'
import { deleteJobOpportunityType, saveJobOpportunities, startGettingJobOpportunitiesData } from '../../constants/job_opportunities_constants'

const INITIAL_STATE = {
  isLoading: true,
  jobOpportunities: []
}

const jobOpportunitiesReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action

  switch (type) {
    case saveJobOpportunities:
      return {
        ...state,
        isLoading: false,
        jobOpportunities: payload
      }

    case startGettingJobOpportunitiesData:
      return {
        ...state,
        isLoading: true
      }

    case logoutUserType:
      return {
        ...state,
        jobOpportunities: [],
        isLoading: true
      }

    case deleteJobOpportunityType:
      let newDataArray = state.jobOpportunities.filter(
        (e, i) => e.PKJobOpportunityId !== payload && e.isDeleted !== true
      )
      return {
        ...state,
        jobOpportunities: newDataArray,
        isLoading: false
      }

    default:
      return state
  }
}

export default jobOpportunitiesReducer
