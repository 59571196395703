import { dataWithFiles } from '../helper/common_functions'
import { apiHandle } from './apiHandler'

// Get All Publications Api
export const getPublicationsApi = async (token, dispatch, navigate) => {
  return await apiHandle(token, dispatch, navigate).get('/publications')
}

// Create New Publication Api
export const createPublicationApi = async (data, token, dispatch, navigate) => {
  return await apiHandle(token, dispatch, navigate).post('/createPublication',dataWithFiles(data))
}

// Update Publication Api
export const updatePublicationApi = async (data, token, dispatch, navigate) => {
  return await apiHandle(token, dispatch, navigate).put(
    `/updatePublication?id=${data.PKPublicationId}`,dataWithFiles(data)
  )
}

// Delete Publication Api
export const deletePublicationApi = async (
  PKPublicationId,
  token,
  dispatch,
  navigate
) => {

  
  return await apiHandle(token, dispatch, navigate).put(`/deletePublication?id=${PKPublicationId}`)
}
