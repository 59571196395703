import React, { useEffect, useState } from 'react'
import logo from '../assets/images/logo/logo.png'
import whiteLogo from '../assets/images/logo/logo_white.png'
import { Routes, Route, Link, useNavigate } from 'react-router-dom'
import {
  Products,
  Publications,
  Events,
  PressReleases,
  Opportunities,
  ContactFormSubmissions,
  Categories,
  Tags
} from './index'
import {
  Stack,
  Typography,
  SvgIcon,
  MenuItem,
  Divider,
  Paper,
  IconButton
} from '@mui/material'
import { FiMenu } from 'react-icons/fi'
import { IoIosArrowBack } from 'react-icons/io'
import { IoNewspaperOutline } from 'react-icons/io5'
import { AiOutlineDashboard } from 'react-icons/ai'
import { BsGrid, BsCalendar4Event } from 'react-icons/bs'
import { AiOutlineUnorderedList } from 'react-icons/ai'
import { CgNotes } from 'react-icons/cg'
import { BsBriefcase } from 'react-icons/bs'
import { HiOutlineTag } from 'react-icons/hi'
import { FaRegNewspaper } from 'react-icons/fa'
import { allStyles } from '../allStyles'
import { logoutUser, RemoveTokens } from '../redux/actions/userActions'
import { useDispatch, useSelector } from 'react-redux'
import { LogoutButton } from '../assets/buttons/LogoutButton'
import { getAllProducts } from '../redux/actions/productsActions'
import { getAllCategories } from '../redux/actions/categoriesActions'
import { getAllTags } from '../redux/actions/tagsActions'
import { getAllContactFormSubmissions } from '../redux/actions/contactformSubmissionsActions'
import { getAllJobOpportunities } from '../redux/actions/jobOpportunitiesAction'
import { getAllPublications } from '../redux/actions/publicationsActions'
import { getAllEvents } from '../redux/actions/eventsActions'
import { getAllPressReleases } from '../redux/actions/pressReleasesActions'
import { Matabs } from './matabs/Matabs'
import { getAllCities } from '../redux/actions/citiesAction'
import { getAllMatabs } from '../redux/actions/MatabAction'

let textColor = '#1D3557'

export const Dashboard = () => {
  const navigate = useNavigate()

  const [sideBarActive, setSideBarActive] = useState(true)

  const userStatus = useSelector(state => state.userStateReducer.user)
  const accessToken = useSelector(
    state => state.userTokensReducer.tokens.accessToken
  )

  const dispatch = useDispatch()

  let logoutHandle = () => {
    logoutUser(dispatch)
    navigate('/')
  }
  useEffect(() => {
    getAllProducts(dispatch, accessToken, navigate)
    getAllPublications(dispatch, accessToken, navigate)
    getAllEvents(dispatch, accessToken, navigate)
    getAllPressReleases(dispatch, accessToken, navigate)
    getAllJobOpportunities(dispatch, accessToken, navigate)
    getAllContactFormSubmissions(dispatch, accessToken, navigate)
    getAllCategories(dispatch, accessToken, navigate)
    getAllTags(dispatch, accessToken, navigate)
    getAllCities(dispatch, accessToken, navigate)
    getAllMatabs(dispatch,accessToken,navigate)

  }, [])

  useEffect(() => {
    if (!userStatus) {
      RemoveTokens(dispatch)
    }
  }, [userStatus])

  let listOfMenuItems = [
    {
      caption: 'Products',
      linkTo: '',
      icon: <BsGrid />,
      element: <Products />
    },
    {
      caption: 'Publications',
      linkTo: 'publications',
      icon: <IoNewspaperOutline />,
      element: <Publications />
    },
    {
      caption: 'Events',
      linkTo: 'events',
      icon: <BsCalendar4Event />,
      element: <Events />
    },
    {
      caption: 'Press Releases',
      linkTo: 'press-releases',
      icon: <FaRegNewspaper />,
      element: <PressReleases />
    },
    {
      caption: 'Opportunities',
      linkTo: 'opportunities',
      icon: <BsBriefcase />,
      element: <Opportunities />
    },
    {
      caption: 'Matabs',
      linkTo: 'matabs',
      icon: <CgNotes />,
      element: <Matabs />
    },
    {
      caption: 'Contact Forms',
      linkTo: 'contact-forms',
      icon: <CgNotes />,
      element: <ContactFormSubmissions />
    },
    {
      caption: 'Categories',
      linkTo: 'categories',
      icon: <AiOutlineUnorderedList />,
      element: <Categories />
    },
    {
      caption: 'Tags',
      linkTo: 'tags',
      icon: <HiOutlineTag />,
      element: <Tags />
    }
  ]

  return (
    <>
      <Stack>
        <Stack className={`sideBar ${sideBarActive ? 'active' : ''}`}>
          <Stack sx={{ py: 3 }}>
            <Stack direction='row' alignItems='center' sx={{mt:'10px'}}>
              {/* <SvgIcon sx={{ overflow: 'initial', marginRight: '25px' }}> */}
                {/* <AiOutlineDashboard color='white' fontSize={30} /> */}
                
                      {sideBarActive ? <Stack sx={{px:3,transition:'all 0.5s ease-in-out'}} ><img width='250px' src={logo} /></Stack> : <Stack  sx={{px:'10px'}} ><img width='50px' src={whiteLogo} /></Stack> }
              {/* </SvgIcon> */}
              {/* <Typography
                variant='h6'
                align='right'
                sx={allStyles.dashboardSideBarHeading}
                noWrap
              >
                Dashboard
              </Typography> */}
            </Stack>

            <Stack sx={{ px: 3 ,mt: '32px' }}>
            <Divider
              sx={{ backgroundColor: 'gray'}}
              orientation='horizontal'
              variant='fullWidth'
              light
            />
            </Stack>

            <Stack sx={{px: 3, py: 3, mt: '15px' }} direction='column' spacing={4}>
              {listOfMenuItems.map((e, i) => (
                <Link key={i} color='white' to={e.linkTo}>
                  <MenuItem
                    disableGutters
                    dense
                    key={i}
                    autoFocus={false}
                    style={{
                      color: 'white',
                      textDecoration: 'none'
                    }}
                  >
                    <Stack direction='row' alignItems='center'>
                      <SvgIcon
                        sx={{
                          overflow: 'initial',
                          color: 'white',
                          fontSize: 23,
                          marginRight: '25px'
                        }}
                      >
                        {e.icon}
                      </SvgIcon>

                      <Stack>
                        <Typography sx={{ fontSize: 20, fontWeight: 'light' }}>
                          {e.caption}
                        </Typography>
                      </Stack>
                    </Stack>
                  </MenuItem>
                </Link>
              ))}
            </Stack>
          </Stack>
        </Stack>

        <Stack className='pages'>
          <Paper
            elevation={2}
            sx={{ px: 3, py: 3, mb: 3, borderRadius: 0 }}
            children={
              <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
              >
                {/* {sideBarActive ? (
                  <IconButton
                    color='primary'
                    onClick={() => setSideBarActive(!sideBarActive)}
                  >
                    {' '}
                    <IoIosArrowBack color={textColor} size={25} />
                  </IconButton>
                ) : (
                  <IconButton
                    color='primary'
                    onClick={() => setSideBarActive(!sideBarActive)}
                  >
                    {' '}
                    <FiMenu color={textColor} size={25} />
                  </IconButton>
                )} */}

                {sideBarActive ? (
                  <Stack direction='row' alignItems='center'>
                    <IconButton
                      color='primary'
                      onClick={() => setSideBarActive(!sideBarActive)}
                    >
                      {' '}
                      <IoIosArrowBack color={textColor} size={25} />
                    </IconButton>

                    {/* <Stack>
                      <img width='100px' src={logo} />
                    </Stack> */}
                  </Stack>
                ) : (
                  <Stack direction='row' alignItems='center'>
                    <IconButton
                      color='primary'
                      onClick={() => setSideBarActive(!sideBarActive)}
                    >
                      {' '}
                      <FiMenu color={textColor} size={25} />
                    </IconButton>

                    {/* <Stack>
                      <img width='100px' src={logo} />
                    </Stack> */}
                  </Stack>
                )}

                <Stack>
                  <LogoutButton onClickHandle={logoutHandle} />
                </Stack>
              </Stack>
            }
          />
          <Stack sx={{ px: 3 }}>
            <Routes>
              {listOfMenuItems.map((e, i) => (
                <Route key={i} path={e.linkTo} element={e.element} />
              ))}
            </Routes>
          </Stack>
        </Stack>
      </Stack>
    </>
  )
}
