import { apiHandle } from "./apiHandler";

// Get All cities Api
export const getCities = async (token, dispatch, navigate) => {
  return await apiHandle(token, dispatch, navigate).get("/cities");
};

// Create New matab Api
export const createMatabApi = async (data, token, dispatch, navigate) => {
  return await apiHandle(token, dispatch, navigate).post(
    "/hamdardMatabs",
    data
  );
};

//   get matab api

export const getMatabApi = async (token, dispatch, navigate) => {
  return await apiHandle(token, dispatch, navigate).get("/hamdardMatabs");
};

// Update Category Api
export const updateMatabApi = async (data, token, dispatch, navigate) => {
  return await apiHandle(token, dispatch, navigate).put(
    `/hamdardMatabs/${data?.id}`,
    data
  );
};
