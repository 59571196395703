import { apiHandle } from './apiHandler'

// Get All Events Api
export const getEventsApi = async (token, dispatch, navigate) => {
  return await apiHandle(token, dispatch, navigate).get('/events')
}

// Create New Event Api
export const createEventApi = async (data, token, dispatch, navigate) => {
  return await apiHandle(token, dispatch, navigate).post('/createEvent',data)
}

// Update Event Api
export const updateEventApi = async (data, token, dispatch, navigate) => {
  return await apiHandle(token, dispatch, navigate).put(
    `/updateEvent?id=${data.PKEventId}`,data
  )
}

// Delete Event Api
export const deleteEventApi = async (
PKEventId,
  token,
  dispatch,
  navigate
) => {
  return await apiHandle(token, dispatch, navigate).put(`/deleteEvent?id=${PKEventId}`)
}
