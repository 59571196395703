import { createTagApi, getTagsApi, updateTagApi } from "../../api/tagsApi"
import { displayErrorToast, displaySuccessToast } from "../../helper/toast_notification_function"
import {saveTags, startGettingTagsData} from "../constants/tags_constants"


 export const  getAllTags = (dispatch,token,navigate) =>{
     dispatch({
        type:startGettingTagsData,        
    })
    getTagsApi(token,dispatch,navigate).then((e)=>{
      return dispatch({
          type:saveTags,
          payload:e.data.Tags ? e.data.Tags : []
          
      })
  })
}


export const createTag = (data, token, dispatch ,navigate) => {
    createTagApi(data, token, dispatch ,navigate)
      .then((res) => {
          displaySuccessToast(`Tag Created successfully!`)
          getAllTags(dispatch,token,navigate)
        })
        .catch(error => {
          displayErrorToast(error)
        })
  }
  
  
  export const updateTag = (data, token, dispatch ,navigate) => {
    updateTagApi(data, token, dispatch ,navigate)
      .then((res) => {
          displaySuccessToast(`Tag Updated successfully!`)
          getAllTags(dispatch,token,navigate)
        })
        .catch(error => {
          displayErrorToast(error)
        })
  }
  


