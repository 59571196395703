import * as React from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import { MdOutlineClose } from 'react-icons/md'
import { ToastContainer } from 'react-toastify'
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField
} from '@mui/material'
import { allStyles } from '../../allStyles'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { formatDateForExtendValidity } from '../../helper/date_format_function'
import { CKEditorComponent } from '../../components/CKEditorComponent'
import { createEvent, updateEvent } from '../../redux/actions/eventsActions'
import { SelectDropdownComponent } from '../../components/SelectDropdownComponent'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}))

const BootstrapDialogTitle = props => {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <MdOutlineClose />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired
}

export const AddUpdateEvent = ({
  open,
  closeClickHandle,
  editState,
  caption
}) => {
  const attachmentTypeListMenu = [
    {
      value: 'youtube',
      caption: 'Youtube Video ID'
    },
    {
      value: 'image',
      caption: 'Image Upload'
    }
  ]

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [formData, setformData] = React.useState({})
  const [attachmentType, setAttachmentType] = React.useState('')

  const [submitButtonDisabled, setSubmitButtonDisabled] = React.useState(true)
  const accessToken = useSelector(
    state => state.userTokensReducer.tokens.accessToken
  )
  React.useEffect(() => {
    setSubmitButtonDisabled(true)
  }, [])

  const closeHandle = () => {
    setSubmitButtonDisabled(true)
    setAttachmentType('')
    closeClickHandle()
  }

  const attachmentTypeHandle = e => {
    setAttachmentType(e.target.value)
    const attachment_type_object = {
      target: {
        name: 'AttachmentType',
        value: e.target.value
      }
    }
    onChangeHandle(attachment_type_object)
  }

  React.useEffect(() => {
    if (editState) {
      if (editState.AttachmentType) {
        setAttachmentType(editState.AttachmentType)
      }
    }
    else {
      setAttachmentType('')
    }

  }, [editState])


  const submitDataHandle = async e => {
    e.preventDefault();
  
    const formDataToSubmit = new FormData();
  
    for (const key in formData) {
      formDataToSubmit.append(key, formData[key]);
    }
  
    if (editState) {
      formDataToSubmit.append('PKEventId', editState.PKEventId);
      await updateEvent(formDataToSubmit, accessToken, dispatch, navigate);
    } else {
      await createEvent(formDataToSubmit, accessToken, dispatch, navigate);
    }
  
    closeClickHandle();
    setformData({});
    setSubmitButtonDisabled(true);
  };
  
  const onChangeHandle = e => {
    const { name, value, files } = e.target;
    const val = files ? files[0] : value;
  
    setformData(prevState => ({
      ...prevState,
      [name]: val,
    }));
  
    setSubmitButtonDisabled(false);
  };
  return (
    <div>
      <BootstrapDialog aria-labelledby='customized-dialog-title' open={open}>
        <BootstrapDialogTitle
          id='customized-dialog-title'
          onClose={closeHandle}
        >
          {editState ? `Update ${caption}` : `Add ${caption}`}
        </BootstrapDialogTitle>
        <form
          autoComplete='off'
          encType='multipart/form-data'
          onSubmit={e => submitDataHandle(e)}
        >
          <DialogContent dividers sx={{ maxHeight: '700px' }}>
            <TextField
              InputProps={{
                inputProps: {
                  defaultValue: editState ? editState.Title : ''
                }
              }}
              name='Title'
              label='Title'
              sx={allStyles.modalFields}
              variant='outlined'
              fullWidth
              required
              onChange={e => onChangeHandle(e)}
            />

            <Stack sx={allStyles.modalFields}>
              <CKEditorComponent
                defaultVal={editState ? editState.Description : ''}
                onChangeHandle={onChangeHandle}
              />
            </Stack>

            <TextField
              InputProps={{
                inputProps: {
                  defaultValue: editState ? editState.ShortDescription : ''
                }
              }}
              name='ShortDescription'
              label='Short Description'
              sx={allStyles.modalFields}
              variant='outlined'
              fullWidth
              required
              onChange={e => onChangeHandle(e)}
            />

            <TextField
              name='Date'
              sx={allStyles.modalFields}
              label='Date'
              fullWidth
              type='date'
              onChange={e => onChangeHandle(e)}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                inputProps: {
                  defaultValue: editState
                    ? formatDateForExtendValidity(editState.Date)
                    : ''
                }
              }}
            />

            <TextField
              InputProps={{
                inputProps: {
                  defaultValue: editState ? editState.Location : ''
                }
              }}
              name='Location'
              label='Location'
              sx={allStyles.modalFields}
              variant='outlined'
              fullWidth
              required
              onChange={e => onChangeHandle(e)}
            />

            <SelectDropdownComponent
              name='AttachmentType'
              caption='Attachment Type'
              sectionTitle='Events'
              onChangeHandle={attachmentTypeHandle}
              menuItems={attachmentTypeListMenu}
              defaultval={editState ? editState.AttachmentType : ''}
            />

            {attachmentType === 'youtube' ? (
              <TextField
                InputProps={{
                  inputProps: {
                    defaultValue:
                      editState && editState.AttachmentType === 'youtube'
                        ? editState.AttachmentLink
                        : ''
                  }
                }}
                name='AttachmentLink'
                label='Youtube Video ID'
                sx={allStyles.modalFields}
                variant='outlined'
                fullWidth
                required
                onChange={e => onChangeHandle(e)}
                helperText='Paste youtube video ID here'
              />
            ) : attachmentType === 'image' ? (

              <TextField
                type='file'
                id='AttachmentLink'
                name='AttachmentLink'
                sx={allStyles.modalFields}
                variant='outlined'
                accept='.jpg, .jpeg, .png'
                fullWidth
                required={editState ? false : true}
                label={`Image Upload${editState ? ' (optional)' : ''}`}
                helperText='Choose image to upload (PNG, JPG)'
                InputLabelProps={{
                  shrink: true
                }}
                onChange={e => onChangeHandle(e)}
              />
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button
              disabled={submitButtonDisabled}
              type='submit'
              sx={{ ...allStyles.actionsButtons, mx: 2, my: 1 }}
              variant='outlined'
            // onClick={closeClickHandle}
            >
              {editState ? `Update ${caption}` : `Add ${caption}`}
            </Button>
          </DialogActions>
        </form>
      </BootstrapDialog>
      <ToastContainer />
    </div>
  )
}
