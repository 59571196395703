import { saveCategories, startGettingCategoriesData } from "../../constants/categories_constants"
import { SAVE_MATABS, START_GETTING_MATABS } from "../../constants/matabs_constant"

const INITIAL_STATE = {
    isLoading:true,
    matabs:{

    }
}



const matabsReducer = (state = INITIAL_STATE,action) => {

    const {type, payload} = action

    switch (type) {
        case SAVE_MATABS:
            return {
                ...state,
                isLoading:false,
                matabs:payload
            }
        case START_GETTING_MATABS:
            return {
                ...state,
                isLoading:true,
            }
        default:
            return state
    }
}


export default matabsReducer

