import { createCategoryApi, getCategoriesApi, updateCategoryApi } from "../../api/categoriesApi"
import { displayErrorToast, displaySuccessToast } from "../../helper/toast_notification_function"
import { createdNewCategoryType, saveCategories, startGettingCategoriesData, updateCategoryType } from "../constants/categories_constants"


 export const  getAllCategories = (dispatch,token,navigate) =>{
     dispatch({
        type:startGettingCategoriesData,        
    })
    getCategoriesApi(token,dispatch,navigate).then((e)=>{
      return dispatch({
          type:saveCategories,
          payload:e.data.Categories ? e.data.Categories : []
          
      })
  })
}

export const createCategory = (data, token, dispatch ,navigate) => {
    createCategoryApi(data, token, dispatch ,navigate)
      .then((res) => {
          displaySuccessToast(`Category Created successfully!`)
          getAllCategories(dispatch,token,navigate)
          // return dispatch({
          //   type: createdNewCategoryType,
          //   payload: res.data.Tags
          // })
        })
        .catch(error => {
          displayErrorToast(error)
        })
  }
  

  export const updateCategory = (data, token, dispatch ,navigate) => {
    updateCategoryApi(data, token, dispatch ,navigate)
      .then((res) => {
          displaySuccessToast(`Category Updated successfully!`)
          getAllCategories(dispatch,token,navigate)
          // return dispatch({
          //   type: updateCategoryType,
          //   payload: data
          // })
        })
        .catch(error => {
          displayErrorToast(error)
        })
  }
  
