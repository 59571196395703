import {
  createMatabApi,
  getMatabApi,
  updateMatabApi,
} from "../../api/matabsApi";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../../helper/toast_notification_function";
import {
  SAVE_MATABS,
  START_GETTING_MATABS,
} from "../constants/matabs_constant";

export const createMatab = (data, token, dispatch, navigate) => {
  createMatabApi(data, token, dispatch, navigate)
    .then((res) => {
      displaySuccessToast(`Product Created successfully!`);
      getAllMatabs(dispatch, token, navigate);

      //   getAllProducts(dispatch,token,navigate)
      // return dispatch({
      //   type: createdNewProductType,
      //   payload: res.data.product
      // })
    })
    .catch((error) => {
      displayErrorToast(error);
    });
};

export const getAllMatabs = (dispatch, token, navigate) => {
  dispatch({
    type: START_GETTING_MATABS,
  });
  getMatabApi(token, dispatch, navigate)
    .then((res) => {
      console.log(res, "lisejfoiwsjofjwsifhi");

      if (res.data.success) {
        dispatch({
          type: SAVE_MATABS,
          payload: res?.data.data,
        });
      }
    })
    .catch((err) => {
      displayErrorToast(err);
    });
};

export const updateMatab = (id, token, dispatch, navigate) => {
  console.log("update matab id log", id);
  updateMatabApi(id, token, dispatch, navigate)
    .then((res) => {
      if (res.data.success) {
        displaySuccessToast(`Matab updated successfully!`);
        getAllMatabs(dispatch, token, navigate);
      }
    })
    .catch((err) => {
      console.log("error in update", err);
      displayErrorToast(err);
    });
};
