import {logoutUserType } from '../../constants/common_constants'
import { deletePublicationType, savePublications, startGettingPublicationsData } from '../../constants/publications_constants'

const INITIAL_STATE = {
  isLoading: true,
  publications: []
}

const publicationsReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action

  switch (type) {
    case savePublications:
      return {
        ...state,
        isLoading: false,
        publications: payload
      }

    case startGettingPublicationsData:
      return {
        ...state,
        isLoading: true
      }

    case logoutUserType:
      return {
        ...state,
        publications: [],
        isLoading: true
      }

    //   case createdNewJobOpportunityType:
    //     state.jobOpportunities.push(payload)
    //     return {
    //         ...state,
    //         jobOpportunities: [...state.jobOpportunities],
    //         isLoading: false
    //       }

    // case updateJobOpportunityType:
    //   let findObj = state.jobOpportunities.find((e, i) => e.PKJobOpportunityId === payload.PKJobOpportunityId)
    //   let findInd = state.jobOpportunities.indexOf(findObj)
    //   state.jobOpportunities[findInd] = {...findObj,...payload}
    //   return {
    //     ...state,
    //     jobOpportunities: [...state.jobOpportunities],
    //     isLoading: false
    //   }

    case deletePublicationType:
      let newDataArray = state.publications.filter(
        (e, i) => e.PKPublicationId !== payload && e.isDeleted !== true
      )
      return {
        ...state,
        publications: newDataArray,
        isLoading: false
      }

    default:
      return state
  }
}

export default publicationsReducer
