import { saveCategories, startGettingCategoriesData } from "../../constants/categories_constants"
import { SAVE_CITIES, START_GETTING_CITIES } from "../../constants/cities_Constant"

const INITIAL_STATE = {
    isLoading:true,
    cities:{

    }
}



const citiesReducer = (state = INITIAL_STATE,action) => {

    const {type, payload} = action

    switch (type) {
        case SAVE_CITIES:
            return {
                ...state,
                isLoading:false,
                cities:payload
            }
        case START_GETTING_CITIES:
            return {
                ...state,
                isLoading:true,
            }
        default:
            return state
    }
}


export default citiesReducer

