import { createPublicationApi, deletePublicationApi, getPublicationsApi, updatePublicationApi } from "../../api/publicationsApi"
import { displayErrorToast, displaySuccessToast } from "../../helper/toast_notification_function"
import { deletePublicationType, savePublications, startGettingPublicationsData } from "../constants/publications_constants"


 export const  getAllPublications = (dispatch,token,navigate) =>{
     dispatch({
        type:startGettingPublicationsData,        
    })
    getPublicationsApi(token,dispatch,navigate).then((e)=>{
      return dispatch({
          type:savePublications,
          payload:e.data.Publications ? e.data.Publications : []
          
      })
  })
}


export const createPublication = (data, token, dispatch ,navigate) => {
    createPublicationApi(data, token, dispatch ,navigate)
    .then((res) => {
        displaySuccessToast(`Publication Created successfully!`)
        getAllPublications(dispatch,token,navigate)
      })
      .catch(error => {
        displayErrorToast(error)
      })
}


export const updatePublication = (data, token, dispatch ,navigate) => {
    updatePublicationApi(data, token, dispatch ,navigate)
    .then((res) => {
        displaySuccessToast(`Publication Updated successfully!`)
        getAllPublications(dispatch,token,navigate)
        // return dispatch({
        //   type: updatePublicationType,
        //   payload: data
        // })
      })
      .catch(error => {
        displayErrorToast(error)
      })
}



export const deletePublication = (selectedDataRow,token,dispatch, navigate) => {
    const selectedPublicationId = selectedDataRow.PKPublicationId
    deletePublicationApi(selectedPublicationId,token,dispatch, navigate)
      .then(() => {
        displaySuccessToast(`${selectedDataRow.Title} deleted successfully`)
        return dispatch({
          type: deletePublicationType,
          payload: selectedPublicationId
        })
      })
      .catch(error => {
        displayErrorToast(error)
      })
  }



