import { logoutUserType } from "../../constants/common_constants"
import {saveTags, startGettingTagsData} from "../../constants/tags_constants" 

const INITIAL_STATE = {
    isLoading:true,
    tags:[],
}


const tagsReducer = (state = INITIAL_STATE,action) => {

    const {type, payload} = action

    switch (type) {
        case saveTags:
            return {
                ...state,
                isLoading:false,
                tags:payload
            }
        case startGettingTagsData:
            return {
                ...state,
                isLoading:true,
            }
            
    case logoutUserType:
        return {
          ...state,
          tags: [],
          isLoading: true
        }
        default:
            return state
    }
}


export default tagsReducer