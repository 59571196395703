import React, { useEffect, useState } from 'react'
import {
  Box,
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Paper,
  TablePagination,
  IconButton,
  Button
} from '@mui/material'
import { PaginationSkeleton } from '../../assets/PaginationSkeleton'
import { RefressButton } from '../../assets/buttons/RefressButton'
import { AddButton } from '../../assets/buttons/AddButton'
import { useDispatch, useSelector } from 'react-redux'
import { RiDeleteBin6Line, RiEdit2Line } from 'react-icons/ri'
import { DeleteConfirmationModal } from '../../components/DeleteConfirmationModal'
import { ToastContainer } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { TableBodyDataSkeleton } from '../../assets/TableBodyDataSkeleton'
import { allStyles } from '../../allStyles'
import { formatDate } from '../../helper/date_format_function'
import { AddUpdatePressRelease } from './AddUpdatePressRelease'
import { deleteSinglePressRelease, getAllPressReleases } from '../../redux/actions/pressReleasesActions'
import { YoutubeEmbedIFrame } from '../../components/YoutubeEmbedIFrame'
import { NoDataSetMessage } from '../../components/NoDataSetMessage'

const columns = [
  { id: 'serialNumber', label: 'S#', minWidth: 20, align: 'center',sortAction:false },
  {
    id: 'Title',
    label: 'Title',
    minWidth: 230,
    align: 'center',
    sortAction:true
  },
  {
    id: 'ShortDescription',
    label: 'Short Description',
    minWidth: 170,
    align: 'center',
    sortAction:true
  },
  {
    id: 'Date',
    label: 'Date',
    minWidth: 100,
    align: 'center',
    sortAction:true
  },
  {
    id: 'IsFeatured',
    label: 'Featured',
    minWidth: 50,
    align: 'center',
    sortAction:true
  },
  {
    id: 'AttachmentType',
    label: 'Type',
    minWidth: 100,
    align: 'center',
    sortAction:false
  },
  {
    id: 'AttachmentLink',
    label: 'Attachment Link',
    minWidth: 100,
    align: 'center',
    sortAction:false
  },
  {
    id: 'actions',
    label: 'Actions',
    minWidth: 170,
    align: 'center',
    sortAction:false
  }
]

export const PressReleases = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [open, setOpen] = useState(false)

  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(true)
  const [orderData, setOrderData] = useState('ASC')
  
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const [deleteModal, setDeleteModal] = React.useState(false)
  const [selectedDataForDelete, setSelectedDataForDelete] = React.useState('')

  const [selectedDataForEdit, setSelectedDataForEdit] = React.useState('')

  const accessToken = useSelector(state => state.userTokensReducer.tokens.accessToken)
  const pressReleasesState = useSelector(state => state.pressReleasesReducer)

  const sortingColumns = col => {
    if(col === 'Date'){
      if (orderData === '1 - 9') {
        const sorted = [...rows].sort((a, b) => (a[col] < b[col] ? 1 : -1))
        setRows([...sorted])
        setOrderData('9 - 1')
      } else if (orderData === '9 - 1') {
        const sorted = [...rows].sort((a, b) => (a[col] > b[col] ? 1 : -1))
        setRows([...sorted])
        setOrderData('1 - 9')
      } else {
        const sorted = [...rows].sort((a, b) => (a[col] > b[col] ? 1 : -1))
        setRows([...sorted])
        setOrderData('1 - 9')
      }
    }
    else{
      if (orderData === 'ASC') {
        const sorted = [...rows].sort((a, b) =>
          a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
        )
        setRows([...sorted])
        setOrderData('DSC')
      } else if (orderData === 'DSC') {
        const sorted = [...rows].sort((a, b) =>
          a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
        )
        setRows([...sorted])
        setOrderData('ASC')
      } else {
        const sorted = [...rows].sort((a, b) =>
          a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
        )
        setRows([...sorted])
        setOrderData('ASC')
      }
    }
}

  useEffect(() => {
    setLoading(pressReleasesState.isLoading)
    setRows(pressReleasesState.pressReleases)
  }, [])

  useEffect(() => {
    setLoading(pressReleasesState.isLoading)
    setRows(pressReleasesState.pressReleases)
  }, [pressReleasesState])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  // Modal open handle through add button
  const addHandle = () => {
    setOpen(true)
  }

  // Modal Close Handle through modal cross button
  const closeClickHandle = () => {
    setOpen(false)

    if (selectedDataForEdit) {
      setSelectedDataForEdit('')
    }
  }

  const refreshHandle = () => {
    getAllPressReleases(dispatch,accessToken,navigate)

  }

  const editHandle = selectedData => {
    setSelectedDataForEdit(selectedData)
    setOpen(true)
  }

  const deleteHandle = selectedData => {
    setSelectedDataForDelete(selectedData)
    setDeleteModal(true)
    
  }

  const confirmDeteHandle = () => {
    deleteSinglePressRelease(selectedDataForDelete,accessToken,dispatch, navigate)
    setSelectedDataForDelete('')
    setDeleteModal(false)
  }

  const cancelDeleteHandle = () => {
    setSelectedDataForDelete('')
    setDeleteModal(false)
  }

  return (
    <Box>
      <Stack
        sx={{ px: 1, mb: 2 }}
        direction='row'
        alignItems='center'
        justifyContent='space-between'
      >
        <Stack>
          <Typography variant='h4'>Press Releases</Typography>
        </Stack>

        <Stack spacing={3} direction='row' alignItems='center'>
          <RefressButton disabled={loading} onClickHandle={refreshHandle} />
          <Stack alignItems={'center'}>
            <AddButton onClickHandle={addHandle} caption='Press Release' />
          </Stack>
        </Stack>
      </Stack>

      <Stack>
        <Paper sx={{ width: '100%' }}>
          <TableContainer sx={allStyles.tableHeight}>
            <Table stickyHeader aria-label='sticky table'>
              <TableHead>
                <TableRow>
                  {columns.map((column, i) => (
                    <TableCell
                      key={i}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.sortAction ? (
                        <Button sx={allStyles.actionsButtons} onClick={() => sortingColumns(column.id)}>
                          {column.label}
                        </Button>
                      ) : (
                        column.label
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              {loading ? (
                <TableBodyDataSkeleton numberOfColumns={columns.length} imagePreview={true} editAction={true} deleteAction={true} />
              ) : rows.length !== 0 ? (
                <TableBody>
                  {rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => {
                      return (
                        <TableRow hover role='checkbox' tabIndex={-1} key={i}>
                          {columns.map((column, ind) => {
                            const value = row[column.id]
                            return column.id === 'AttachmentLink' ? (
                              row.AttachmentType === 'image' ? <TableCell key={ind} align={column.align}>
                              <Box sx={{ width: '200px' }}>
                                <img
                                  src={process.env.REACT_APP_IMAGE_PATH_URL + value}
                                  alt=''
                                  width='100%'
                                />
                              </Box>
                            </TableCell> : row.AttachmentType === 'youtube' ? <TableCell key={ind} align={column.align}>
                                <Stack >
                                <YoutubeEmbedIFrame  youtubeId={value}  />
                                </Stack>
                              </TableCell> : <TableCell key={ind} align={column.align}>--- Not Set Yet ---</TableCell>
                            ) : column.id === 'Date' ? (
                              <TableCell key={ind} align={column.align}>
                                {value ? formatDate(value): '--'}
                              </TableCell>
                            ) : column.id === 'serialNumber' ? (
                              <TableCell key={ind} align={column.align}>
                                {page < 1
                                  ? i + 1
                                  : page * rowsPerPage + (i + 1)}
                              </TableCell>
                            ) : column.id === 'IsFeatured' ? (
                              <TableCell key={ind} align={column.align}>
                                {value === true ? 'Yes' : 'No'}
                              </TableCell>
                            ) : column.id === 'actions' ? (
                              <TableCell key={ind} align={column.align}>
                                <Stack
                                  direction='row'
                                  spacing={2}
                                  justifyContent='center'
                                >
                                  <IconButton
                                    onClick={() => editHandle(row)}
                                    aria-label='edit'
                                  >
                                    <RiEdit2Line color='#70db70' />
                                  </IconButton>

                                  <IconButton
                                    onClick={() => deleteHandle(row)}
                                    aria-label='delete'
                                  >
                                    <RiDeleteBin6Line color='#fb8484' />
                                  </IconButton>
                                </Stack>
                              </TableCell>
                            ) : (
                              <TableCell key={ind} align={column.align}>
                                {value}
                              </TableCell>
                            )
                          })}
                        </TableRow>
                      )
                    })}
                </TableBody>
              ) : <NoDataSetMessage lengthOfColumns={columns.length} />}
            </Table>
          </TableContainer>

          {loading ? (
            <PaginationSkeleton />
          ) : (
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component='div'
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Paper>
      </Stack>

      <AddUpdatePressRelease
        open={open}
        closeClickHandle={closeClickHandle}
        editState={selectedDataForEdit}
        caption='Press Release'
      />
      <DeleteConfirmationModal
        deleteConfirmationOpen={deleteModal}
        ItemCategory={'Press Release'}
        cancelHandle={cancelDeleteHandle}
        confirmDeteHandle={confirmDeteHandle}
        selectedData={selectedDataForDelete ? selectedDataForDelete : ''}
        title={'Title'}
      />
      <ToastContainer />
    </Box>
  )
}
