import { apiHandle } from "./apiHandler"


// Get All Categories Api 
export const getCategoriesApi = async (token,dispatch, navigate) => {
    return await apiHandle(token,dispatch,navigate).get('/categories')
}

// Create New Category Api
export const createCategoryApi = async (data, token, dispatch, navigate) => {
    return await apiHandle(token, dispatch, navigate).post('/createCategory',data)
  }
  
  // Update Category Api
  export const updateCategoryApi = async (data, token, dispatch, navigate) => {
    return await apiHandle(token, dispatch, navigate).put(`/updateCategory?id=${data.PKCategoryId}`,{NameOfCategory:data.NameOfCategory})
  }
  