import {logoutUserType } from '../../constants/common_constants'
import { deleteEventType, saveEvents, startGettingEventsData } from '../../constants/events_constants'

const INITIAL_STATE = {
  isLoading: true,
  events: []
}

const eventsReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action

  switch (type) {
    case saveEvents:
      return {
        ...state,
        isLoading: false,
        events: payload
      }

    case startGettingEventsData:
      return {
        ...state,
        isLoading: true
      }

    case logoutUserType:
      return {
        ...state,
        events: [],
        isLoading: true
      }

    case deleteEventType:
      let newDataArray = state.events.filter(
        (e, i) => e.PKEventId !== payload && e.isDeleted !== true
      )
      return {
        ...state,
        events: newDataArray,
        isLoading: false
      }

    default:
      return state
  }
}

export default eventsReducer
