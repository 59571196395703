import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import { MdOutlineClose } from "react-icons/md";
import { ToastContainer } from "react-toastify";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { allStyles } from "../../allStyles";
import { useDispatch, useSelector } from "react-redux";
import { TagsCheckBoxes } from "./TagsCheckBoxes";
import {
  createProduct,
  updateProduct,
} from "../../redux/actions/productsActions";
import { useNavigate } from "react-router-dom";
import { CKEditorComponent } from "../../components/CKEditorComponent";
import { SelectDropdownComponent } from "../../components/SelectDropdownComponent";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <MdOutlineClose />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export const AddUpdateProduct = ({ open, closeClickHandle, editState }) => {
  const genderListMenu = [
    {
      value: "m",
      caption: "Male",
    },
    {
      value: "f",
      caption: "Female",
    },
    {
      value: "b",
      caption: "Both",
    },
  ];

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formData, setformData] = React.useState({});

  const [submitButtonDisabled, setSubmitButtonDisabled] = React.useState(true);

  const [categories, setCategories] = React.useState([]);
  const [categoriesLoading, setCategoriesLoading] = React.useState(true);

  const [tags, setTags] = React.useState([]);
  const [tagsLoading, setTagsLoading] = React.useState(true);
  const state = useSelector((state) => state);

  React.useEffect(() => {
    if (state.categoriesReducer.categories.length) {
      const Newcategories = state.categoriesReducer?.categories?.map((e) => {
        return {
          value: e.PKCategoryId,
          caption: e.NameOfCategory,
        };
      });
      setCategories(Newcategories);
    }
    setCategoriesLoading(state.categoriesReducer.isLoading);

    setTags(state.tagsReducer.tags);
    setTagsLoading(state.tagsReducer.isLoading);
    setSubmitButtonDisabled(true);
  }, []);

  React.useEffect(() => {
    if (state.categoriesReducer.categories.length) {
      const Newcategories = state.categoriesReducer?.categories?.map((e) => {
        return {
          value: e.PKCategoryId,
          caption: e.NameOfCategory,
        };
      });
      setCategories(Newcategories);
    }
    setCategoriesLoading(state.categoriesReducer.isLoading);

    setTags(state.tagsReducer.tags);
    setTagsLoading(state.tagsReducer.isLoading);
  }, [state]);

  const accessToken = useSelector(
    (state) => state.userTokensReducer.tokens.accessToken
  );

  const closeHandle = () => {
    setSubmitButtonDisabled(true);
    closeClickHandle();
  };

  console.log(editState, "product edir", formData, "form dete");

  const submitDataHandle = (e) => {
    e.preventDefault();

    if (editState) {
      formData.PKProductId = editState.PKProductId;
      updateProduct(formData, accessToken, dispatch, navigate);
      closeClickHandle();
    } else {
      createProduct(formData, accessToken, dispatch, navigate);
      closeClickHandle();
      setformData({});
    }
  };

  const onChangeHandle = (e) => {
    let name = e.target.name;
    let val = e.target;
    if (name === "Image") {
      setformData({ ...formData, [e.target.name]: val.files[0] });
    } else {
      setformData({ ...formData, [name]: val.value });
    }

    setSubmitButtonDisabled(false);
  };

  console.log("Ctegoryyyy loggg", categories);

  return (
    <div>
      <BootstrapDialog aria-labelledby="customized-dialog-title" open={open}>
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={closeHandle}
        >
          {editState ? "Update Product" : "Add Product"}
        </BootstrapDialogTitle>
        <form
          autoComplete="off"
          encType="multipart/form-data"
          onSubmit={(e) => submitDataHandle(e)}
        >
          <DialogContent dividers sx={{ maxHeight: "700px" }}>
            <TextField
              defaultValue={editState ? editState.Title : ""}
              name="Title"
              sx={allStyles.modalFields}
              label="Title"
              variant="outlined"
              fullWidth
              required
              onChange={(e) => onChangeHandle(e)}
            />

            <Stack sx={allStyles.modalFields}>
              <CKEditorComponent
                defaultVal={editState ? editState.Description : ""}
                onChangeHandle={onChangeHandle}
              />
            </Stack>

            <TextField
              defaultValue={editState ? editState.Price : ""}
              name="Price"
              // value={Price}
              // onChange={e => onChange(e)}
              // className={classes.field}
              sx={allStyles.modalFields}
              label="Price"
              type="number"
              variant="outlined"
              fullWidth
              onChange={(e) => onChangeHandle(e)}
              required
            />

            <TextField
              defaultValue={editState ? editState.Quantity : ""}
              name="Quantity"
              // value={Quantity}
              // onChange={e => onChange(e)}
              // className={classes.field}
              sx={allStyles.modalFields}
              label="Quantity"
              type="number"
              variant="outlined"
              fullWidth
              onChange={(e) => onChangeHandle(e)}
              required
            />

            <TextField
              defaultValue={editState ? editState.Unit : ""}
              name="Unit"
              // value={Unit}
              // onChange={e => onChange(e)}
              // className={classes.field}
              sx={allStyles.modalFields}
              label="Unit"
              type="text"
              variant="outlined"
              fullWidth
              onChange={(e) => onChangeHandle(e)}
              required
            />

            <SelectDropdownComponent
              name="Gender"
              caption="Gender"
              sectionTitle="ProductsGender"
              onChangeHandle={onChangeHandle}
              menuItems={genderListMenu}
              defaultval={editState ? editState.Gender : ""}
            />

            {!categoriesLoading && (
              <SelectDropdownComponent
                name="FKCategoryId"
                caption="Category"
                sectionTitle="ProductsCategory"
                onChangeHandle={onChangeHandle}
                menuItems={categories}
                defaultval={editState ? editState.category.PKCategoryId : ""}
              />
            )}

            {!tagsLoading && (
              <TagsCheckBoxes
                allTags={tags}
                onChange={onChangeHandle}
                tags={editState ? editState.tagProducts : null}
              />
            )}

            <Stack sx={{ mt: 2 }}>
              <TextField
                type="file"
                id="Image"
                name="Image"
                sx={allStyles.modalFields}
                variant="outlined"
                accept=".jpg, .jpeg, .png"
                fullWidth
                helperText="Choose image to upload (PNG, JPG)"
                required={editState ? false : true}
                label={`Image Upload${editState ? " (optional)" : ""}`}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => onChangeHandle(e)}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={submitButtonDisabled}
              type="submit"
              sx={{ ...allStyles.actionsButtons, mx: 2, my: 1 }}
              variant="outlined"
            >
              {editState ? "Update Product" : "Create Product"}
            </Button>
          </DialogActions>
        </form>
      </BootstrapDialog>
      <ToastContainer />
    </div>
  );
};
