import * as React from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { CircularProgress, Grid, IconButton, Stack, Typography } from '@mui/material'
import { allStyles } from '../../allStyles'
import { useEffect } from 'react'
import { useState } from 'react'
import { MdOutlineClose } from 'react-icons/md'
import { formatDate } from '../../helper/date_format_function'
import { useSelector } from 'react-redux'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}))

let renderData = [
  {
    caption: 'Full Name',
    id: 'FullName',
    col: 4
  },
  {
    caption: 'ContactNumber',
    id: 'ContactNumber',
    col: 4
  },
  {
    caption: 'Email',
    id: 'Email',
    col: 4
  },
  {
    caption: 'Message',
    id: 'Message',
    col: 12
  },
  {
    caption: 'Dated',
    id: 'createdAt',
    col: 4
  },
  
]


const BootstrapDialogTitle = props => {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <MdOutlineClose />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired
}

export const ContactFormDetails = ({
  open,
  closeClickHandle,
  selectedData
}) => {


  const [loading, setLoading] = useState(true)

  const [dataToView, setDataToView] = useState(null)


  const contactFormDataState = useSelector(state => state.contactFormDataReducer)


  const closeHandle = () => {
    closeClickHandle()
  }


  useEffect(() => {

    if(selectedData){
      setDataToView(selectedData)
    }
  }, [selectedData])

  useEffect(() => {
    setLoading(selectedData.isLoading)
  }, [contactFormDataState])


  return (
    <div>
      <BootstrapDialog
        maxWidth='xl'
        aria-labelledby='customized-dialog-title'
        open={open}
      >
        <BootstrapDialogTitle
          id='customized-dialog-title'
          onClose={closeHandle}
        >
          Contact Form
        </BootstrapDialogTitle>
        <DialogContent dividers sx={{ maxHeight: '720px', minWidth: 750 }}>
            {loading ? <Stack> <CircularProgress /> </Stack> : <Stack spacing={3}>   
              <Stack spacing={2}>
                <Grid container>
                  {renderData.map((e, i) => (
                    <Grid key={i} item md={e.col} lg={e.col} xl={e.col} sx={{ mb: 3 }}>
                      {e.id === 'createdAt' ? <Stack direction='row' spacing={2}>
                        <Typography sx={{ fontWeight: 'bold' }}>
                          {e.caption}:
                        </Typography>
                        <Typography>
                          { dataToView ?  formatDate(dataToView[e.id]) : ''}
                        </Typography>
                      </Stack> : <Stack direction='row' spacing={2}>
                      <Typography sx={{ fontWeight: 'bold' }}>
                          {e.caption}:
                        </Typography>
                        <Typography>
                          {dataToView ? dataToView[e.id] : ''}
                        </Typography>
                      </Stack> }
                    </Grid>
                  ))}
                </Grid>
              </Stack>
            </Stack>}
        </DialogContent>
        <DialogActions>
            <Stack direction='row' spacing={3} sx={{ mx: 2, my: 1 }}>
              <Button
                sx={allStyles.actionsButtons}
                variant='outlined'
                color='info'
                onClick={closeHandle}
              >
                Close
              </Button>
            </Stack>
        </DialogActions>
      </BootstrapDialog>
    </div>
  )
}
