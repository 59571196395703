import { dataWithFiles } from '../helper/common_functions'
import { apiHandle } from './apiHandler'

// Get All Press Releases Api
export const getPressReleasesApi = async (token, dispatch, navigate) => {
  return await apiHandle(token, dispatch, navigate).get('/pressReleases')
}

// Create New Press Release Api
export const createPressReleaseApi = async (data, token, dispatch, navigate) => {
  return await apiHandle(token, dispatch, navigate).post('/createPressRelease',dataWithFiles(data))
}

// Update Press Release Api
export const updatePressReleaseApi = async (data, token, dispatch, navigate) => {
  return await apiHandle(token, dispatch, navigate).put(
    `/updatePressRelease?id=${data.PKPressReleaseId}`,dataWithFiles(data))}

// Delete Press Release Api
export const deletePressReleaseApi = async (
PKPressReleaseId,
  token,
  dispatch,
  navigate
) => {
  return await apiHandle(token, dispatch, navigate).put(`/deletePressRelease?id=${PKPressReleaseId}`)
}
