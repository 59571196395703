import { Typography } from '@mui/material'
import React from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// // import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import { Editor } from '@ckeditor/ckeditor5-core';

export const CKEditorComponent = ({ defaultVal, onChangeHandle }) => {
  return (
    <>
      <CKEditor
        editor={ClassicEditor}
        data={defaultVal ? defaultVal : ''}
        onChange={(event, editor) => {
          const data = {
            target: {
              value: editor.getData(),
              name: 'Description'
            }
          }
          onChangeHandle(data)
        }}
      />
    </>
  )
}

