import { dataWithFiles } from '../helper/common_functions'
import { apiHandle } from './apiHandler'

// Get All Products Api
export const getProductsApi = async (token, dispatch, navigate) => {
  return await apiHandle(token, dispatch, navigate).get('/products')
}

// Create New Product Api
export const createProductApi = async (data, token, dispatch, navigate) => {
  return await apiHandle(token, dispatch, navigate).post(
    '/createProduct',dataWithFiles(data)
  )
}

// Update Product Api
export const updateProductsApi = async (data, token, dispatch, navigate) => {
  return await apiHandle(token, dispatch, navigate).put(
    `/updateProduct?id=${data.PKProductId}`,
    dataWithFiles(data)
  )
}

// Delete Product Api
export const deleteProductApi = async (
  productId,
  token,
  dispatch,
  navigate
) => {
  return await apiHandle(token, dispatch, navigate).put(`/deleteProduct?id=${productId}`)
}
