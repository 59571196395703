import { saveCategories, startGettingCategoriesData } from "../../constants/categories_constants"

const INITIAL_STATE = {
    isLoading:true,
    categories:{

    }
}



const categoriesReducer = (state = INITIAL_STATE,action) => {

    const {type, payload} = action

    switch (type) {
        case saveCategories:
            return {
                ...state,
                isLoading:false,
                categories:payload
            }
        case startGettingCategoriesData:
            return {
                ...state,
                isLoading:true,
            }
        default:
            return state
    }
}


export default categoriesReducer

