import { createCategoryApi, getCategoriesApi, updateCategoryApi } from "../../api/categoriesApi"
import { getCities } from "../../api/matabsApi"
import { displayErrorToast, displaySuccessToast } from "../../helper/toast_notification_function"
import { createdNewCategoryType, saveCategories, startGettingCategoriesData, updateCategoryType } from "../constants/categories_constants"
import { SAVE_CITIES, START_GETTING_CITIES } from "../constants/cities_Constant"


 export const  getAllCities = (dispatch,token,navigate) =>{

     dispatch({
        type:START_GETTING_CITIES,        
    })
    getCities(token,dispatch,navigate).then((e)=>{
        console.log(e.data.data,"[===================");
      return dispatch({
          type:SAVE_CITIES,
          payload:e.data.data ? e.data.data : []
          
      })
  })
}

// export const createCategory = (data, token, dispatch ,navigate) => {
//     createCategoryApi(data, token, dispatch ,navigate)
//       .then((res) => {
//           displaySuccessToast(`Category Created successfully!`)
//           getAllCategories(dispatch,token,navigate)
//           // return dispatch({
//           //   type: createdNewCategoryType,
//           //   payload: res.data.Tags
//           // })
//         })
//         .catch(error => {
//           displayErrorToast(error)
//         })
//   }
  

//   export const updateCategory = (data, token, dispatch ,navigate) => {
//     updateCategoryApi(data, token, dispatch ,navigate)
//       .then((res) => {
//           displaySuccessToast(`Category Updated successfully!`)
//           getAllCategories(dispatch,token,navigate)
//           // return dispatch({
//           //   type: updateCategoryType,
//           //   payload: data
//           // })
//         })
//         .catch(error => {
//           displayErrorToast(error)
//         })
//   }
  
