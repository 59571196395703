import { createProductApi, deleteProductApi, getProductsApi, updateProductsApi } from "../../api/productsApi"
import { displayErrorToast, displaySuccessToast } from "../../helper/toast_notification_function"
import { createdNewProductType, deleteProductType, saveProducts, startGettingProductsData, updateProductType} from "../constants/products_constants"


 export const  getAllProducts = (dispatch,token,navigate) =>{
     dispatch({
        type:startGettingProductsData,        
    })
    getProductsApi(token,dispatch,navigate).then((e)=>{
      return dispatch({
          type:saveProducts,
          payload:e.data.Products ? e.data.Products : []
          
      })
  })
}


export const createProduct = (data, token, dispatch ,navigate) => {
  createProductApi(data, token, dispatch ,navigate)
  .then((res) => {
    displaySuccessToast(`Product Created successfully!`)
    getAllProducts(dispatch,token,navigate)
        // return dispatch({
        //   type: createdNewProductType,
        //   payload: res.data.product
        // })
      })
      .catch(error => {
        displayErrorToast(error)
      })
}


export const updateProduct = (data, token, dispatch ,navigate) => {
    updateProductsApi(data, token, dispatch ,navigate)
    .then((res) => {
        displaySuccessToast(`Product Updated successfully!`)
        getAllProducts(dispatch,token,navigate)
        // return dispatch({
        //   type: updateProductType,
        //   payload: data
        // })
      })
      .catch(error => {
        displayErrorToast(error)
      })
}



export const deleteSingleProduct = (selectedDataRow,token,dispatch, navigate) => {
    const selectedProductId = selectedDataRow.PKProductId
    deleteProductApi(selectedProductId,token,dispatch, navigate)
      .then(() => {
        displaySuccessToast(`${selectedDataRow.Title} deleted successfully!`)
        return dispatch({
          type: deleteProductType,
          payload: selectedProductId
        })
      })
      .catch(error => {
        displayErrorToast(error)
      })
  }



