import { createPressReleaseApi, deletePressReleaseApi, getPressReleasesApi, updatePressReleaseApi } from "../../api/pressReleasesApi"
import { displayErrorToast, displaySuccessToast } from "../../helper/toast_notification_function"
import { deletePressReleaseType, savePressReleases, startGettingPressReleasesData } from "../constants/press_releases_constants"


 export const  getAllPressReleases = (dispatch,token,navigate) =>{
     dispatch({
        type:startGettingPressReleasesData,        
    })
    getPressReleasesApi(token,dispatch,navigate).then((e)=>{
      return dispatch({
          type:savePressReleases,
          payload:e.data.PressReleases ? e.data.PressReleases : []
          
      })
  })
}


export const createPressRelease = (data, token, dispatch ,navigate) => {
createPressReleaseApi(data, token, dispatch ,navigate)
  .then((res) => {
    displaySuccessToast(`PressRelease Created successfully!`)
    getAllPressReleases(dispatch,token,navigate)
      })
      .catch(error => {
        displayErrorToast(error)
      })
}


export const updatePressRelease = (data, token, dispatch ,navigate) => {
    updatePressReleaseApi(data, token, dispatch ,navigate)
    .then((res) => {
        displaySuccessToast(`PressRelease Updated successfully!`)
        getAllPressReleases(dispatch,token,navigate)
      })
      .catch(error => {
        displayErrorToast(error)
      })
}



export const deleteSinglePressRelease= (selectedDataRow,token,dispatch, navigate) => {
    const selectedPressReleaseId = selectedDataRow.PKPressReleaseId
    deletePressReleaseApi(selectedPressReleaseId,token,dispatch, navigate)
      .then(() => {
        displaySuccessToast(`${selectedDataRow.Title} deleted successfully!`)
        return dispatch({
          type: deletePressReleaseType,
          payload: selectedPressReleaseId
        })
      })
      .catch(error => {
        displayErrorToast(error)
      })
  }



