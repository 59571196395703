import {logoutUserType } from '../../constants/common_constants'
import {
    createdNewProductType,
  deleteProductType,
  saveProducts,
  startGettingProductsData,
  updateProductType
} from '../../constants/products_constants'

const INITIAL_STATE = {
  isLoading: true,
  products: []
}

const productsReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action

  switch (type) {
    case saveProducts:
      return {
        ...state,
        isLoading: false,
        products: payload
      }

    case startGettingProductsData:
      return {
        ...state,
        isLoading: true
      }

    case logoutUserType:
      return {
        ...state,
        products: [],
        isLoading: true
      }

    //   case createdNewProductType:
    //     state.products.push(payload)
    //     return {
    //         ...state,
    //         products: [...state.products],
    //         isLoading: false
    //       }

    // case updateProductType:
    //   let findObj = state.products.find((e, i) => e.PKProductId === payload.PKProductId)
    //   let findInd = state.products.indexOf(findObj)
    //   state.products[findInd] = {...findObj,...payload}
    //   return {
    //     ...state,
    //     products: [...state.products],
    //     isLoading: false
    //   }

    case deleteProductType:
      let newDataArray = state.products.filter(
        (e, i) => e.PKProductId !== payload && e.isDeleted !== true
      )
      return {
        ...state,
        products: newDataArray,
        isLoading: false
      }

    default:
      return state
  }
}

export default productsReducer
