import {logoutUserType } from '../../constants/common_constants'
import { deletePressReleaseType, savePressReleases, startGettingPressReleasesData } from '../../constants/press_releases_constants'

const INITIAL_STATE = {
  isLoading: true,
  pressReleases: []
}

const pressReleasesReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action

  switch (type) {
    case savePressReleases:
      return {
        ...state,
        isLoading: false,
        pressReleases: payload
      }

    case startGettingPressReleasesData:
      return {
        ...state,
        isLoading: true
      }

    case logoutUserType:
      return {
        ...state,
        pressReleases: [],
        isLoading: true
      }

    case deletePressReleaseType:
      let newDataArray = state.pressReleases.filter(
        (e, i) => e.PKPressReleaseId !== payload && e.isDeleted !== true
      )
      return {
        ...state,
        pressReleases: newDataArray,
        isLoading: false
      }

    default:
      return state
  }
}

export default pressReleasesReducer
