import { Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'

export const YoutubeEmbedIFrame = ({youtubeId}) => {

    const [youtubeVideoId, setYoutubeVideoId] = useState('')
    useEffect(() => {

        
        if(youtubeId.slice(0,6) !== 'https:'){
            setYoutubeVideoId(youtubeId)
            // const splitYoutubeLink = youtubeId.split('/')
            // // const id =  splitYoutubeLink[4].split('?')[0]
            // // setYoutubeVideoId(id)
        }
        else{
            setYoutubeVideoId('')
        }
    }, [youtubeId])
    



  return (
  <Stack>
  {
 youtubeVideoId ? 
  <iframe width="200" height="130" src={`https://www.youtube.com/embed/${youtubeVideoId}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> 
: <Typography align='center'>Preview Not Available</Typography>}
  </Stack>
  )
}
