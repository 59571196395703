import { createEventApi, deleteEventApi, getEventsApi, updateEventApi } from "../../api/eventsApi"
import { displayErrorToast, displaySuccessToast } from "../../helper/toast_notification_function"
import { deleteEventType, saveEvents, startGettingEventsData } from "../constants/events_constants"


 export const  getAllEvents = (dispatch,token,navigate) =>{
     dispatch({
        type:startGettingEventsData,        
    })
    getEventsApi(token,dispatch,navigate).then((e)=>{
      return dispatch({
          type:saveEvents,
          payload:e.data.Events ? e.data.Events : []
          
      })
  })
}


export const createEvent = (data, token, dispatch ,navigate) => {
  createEventApi(data, token, dispatch ,navigate)
  .then((res) => {
    displaySuccessToast(`Event Created successfully!`)
    getAllEvents(dispatch,token,navigate)
      })
      .catch(error => {
        displayErrorToast(error)
      })
}


export const updateEvent = (data, token, dispatch ,navigate) => {
    updateEventApi(data, token, dispatch ,navigate)
    .then((res) => {
        displaySuccessToast(`Event Updated successfully!`)
        getAllEvents(dispatch,token,navigate)
      })
      .catch(error => {
        displayErrorToast(error)
      })
}



export const deleteSingleEvent = (selectedDataRow,token,dispatch, navigate) => {
    const selectedEventId = selectedDataRow.PKEventId
    deleteEventApi(selectedEventId,token,dispatch, navigate)
      .then(() => {
        displaySuccessToast(`${selectedDataRow.Title} deleted successfully!`)
        return dispatch({
          type: deleteEventType,
          payload: selectedEventId
        })
      })
      .catch(error => {
        displayErrorToast(error)
      })
  }



