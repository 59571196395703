import * as React from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import { MdOutlineClose } from 'react-icons/md'
import { ToastContainer } from 'react-toastify'
import { Stack, TextField } from '@mui/material'
import { allStyles } from '../../allStyles'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { formatDateForExtendValidity } from '../../helper/date_format_function'
import { createJobOpportunity, updateJobOpportunity } from '../../redux/actions/jobOpportunitiesAction'
import { CKEditorComponent } from '../../components/CKEditorComponent'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}))

const BootstrapDialogTitle = props => {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <MdOutlineClose />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired
}

export const AddUpdateOpportunity = ({
  open,
  closeClickHandle,
  editState,
  caption
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [formData, setformData] = React.useState({})

  const [submitButtonDisabled, setSubmitButtonDisabled] = React.useState(true)
  const accessToken = useSelector(
    state => state.userTokensReducer.tokens.accessToken
  )
  React.useEffect(() => {
    setSubmitButtonDisabled(true)
  }, [])

  const closeHandle = () => {
    setSubmitButtonDisabled(true)
    closeClickHandle()
  }

  const submitDataHandle = e => {
    e.preventDefault()
    if (editState) {
      formData.PKJobOpportunityId = editState.PKJobOpportunityId
      updateJobOpportunity(formData, accessToken, dispatch, navigate)
      closeClickHandle()
      setformData({})
    } else {
      createJobOpportunity(formData, accessToken, dispatch, navigate)
      closeClickHandle()
      setformData({})
    }
  }

  const onChangeHandle = e => {
    let name = e.target.name
    let val = e.target

    if (name === 'Image') {
      setformData({ ...formData, [e.target.name]: val.files[0] })
    } else {
      setformData({ ...formData, [name]: val.value })
    }

    setSubmitButtonDisabled(false)
  }

  return (
    <div>
      <BootstrapDialog aria-labelledby='customized-dialog-title' open={open}>
        <BootstrapDialogTitle
          id='customized-dialog-title'
          onClose={closeHandle}
        >
          {editState ? `Update ${caption}` : `Add ${caption}`}
        </BootstrapDialogTitle>
        <form
          autoComplete='off'
          encType='multipart/form-data'
          onSubmit={e => submitDataHandle(e)}
        >
          <DialogContent dividers sx={{ maxHeight: '700px' }}>
            <TextField
              InputProps={{
                inputProps: {
                  defaultValue: editState ? editState.Title : ''
                }
              }}
              name='Title'
              label='Title'
              sx={allStyles.modalFields}
              variant='outlined'
              fullWidth
              required
              onChange={e => onChangeHandle(e)}
            />

            <Stack sx={allStyles.modalFields}>


            <CKEditorComponent defaultVal={editState ? editState.Description : ''} onChangeHandle={onChangeHandle} />
              
            </Stack>

            <TextField
              InputProps={{
                inputProps: {
                  defaultValue: editState ? editState.ShortDescription : ''
                }
              }}
              name='ShortDescription'
              label='Short Description'
              sx={allStyles.modalFields}
              variant='outlined'
              fullWidth
              required
              onChange={e => onChangeHandle(e)}
            />

            <TextField
              name='DueDate'
              sx={allStyles.modalFields}
              label='Due Date'
              fullWidth
              type='date'
              onChange={e => onChangeHandle(e)}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                inputProps: {
                  defaultValue: editState
                    ? formatDateForExtendValidity(editState.DueDate)
                    : ''
                }
              }}
            />

            <TextField
              InputProps={{
                inputProps: {
                  defaultValue: editState ? editState.Location : ''
                }
              }}
              name='Location'
              label='Location'
              sx={allStyles.modalFields}
              variant='outlined'
              fullWidth
              required
              onChange={e => onChangeHandle(e)}
            />

            <TextField
              InputProps={{
                inputProps: {
                  defaultValue: editState ? editState.Department : ''
                }
              }}
              name='Department'
              label='Department'
              sx={allStyles.modalFields}
              variant='outlined'
              fullWidth
              required
              onChange={e => onChangeHandle(e)}
            />

            <TextField
              InputProps={{
                inputProps: {
                  defaultValue: editState ? editState.Position : ''
                }
              }}
              name='Position'
              label='Position'
              sx={allStyles.modalFields}
              variant='outlined'
              fullWidth
              required
              onChange={e => onChangeHandle(e)}
            />

            <TextField
              InputProps={{
                inputProps: {
                  defaultValue: editState ? editState.Contract : ''
                }
              }}
              name='Contract'
              label='Contract'
              sx={allStyles.modalFields}
              variant='outlined'
              fullWidth
              required
              onChange={e => onChangeHandle(e)}
            />
          </DialogContent>
          <DialogActions>
            <Button
              disabled={submitButtonDisabled}
              type='submit'
              sx={{...allStyles.actionsButtons, mx: 2, my: 1 }}
              variant='outlined'
              // onClick={closeClickHandle}
            >
              {editState ? `Update ${caption}` : `Add ${caption}`}
            </Button>
          </DialogActions>
        </form>
      </BootstrapDialog>
      <ToastContainer />
    </div>
  )
}
